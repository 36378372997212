<template>
  <v-card width="90%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Импортирование технологических карт</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pt-2 px-0">
      <v-row no-gutters justify="space-between" class="px-5">
        <v-col cols="4" class="px-2">
          <v-select
            label="Модуль"
            :items="modules"
            item-text="description"
            v-model="selectedModule"
            return-object
            @change="updateCards"
          />
        </v-col>
        <v-col cols="4" class="px-2">
          <StaffSearchField
            v-model="accepter"
            :queryParams="{ roles: 1 }"
            label="Утверждающий"
          />
        </v-col>
        <v-col cols="3" class="px-2">
          <v-checkbox label="Требуется согласование" v-model="requireAgree"/>
        </v-col>
        <v-col cols="12" class="px-2" v-if="requireAgree">
          <v-row>
            <v-col cols="6" class="px-2">
              <OrganizationSearchField
                v-model="agreederOrg"
                label="Согласующая организация"
              />
            </v-col>
            <v-col cols="6" class="px-2">
              <StaffSearchField
                v-model="agreeder"
                :queryParams="{ orgId: agreederOrgId, roles: 3 }"
                label="Согласующий"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <CardsTable
            v-if="selectedModule"
            :url="selectedModule.baseUrl + '/card/index'"
            :key="cardsTableUK"
            @changed="cardTableChanged"
            >
            <template v-slot:select="{ card }">
              <v-checkbox
                :value="card"
                v-model="selectedCards"
              />
            </template>
            <template v-slot:action="{ card }">
              <v-btn
                icon
                :to="selectedModule.baseUrl + '/card/view?id=' + card.id"
                target="_blank"
                >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </CardsTable>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="default"
        :loading="sending"
        :disabled="sending"
        @click="$router.go(-1)"
        >
        Отмена
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :loading="sending"
        :disabled="disableNext()"
        @click="select"
        >
        Создать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CardsTable from './components/CardsTable.vue'
import StaffSearchField from '@/components/StaffSearchField.vue'
import OrganizationSearchField from '@/components/OrganizationSearchField.vue'

export default {
  components: { CardsTable, StaffSearchField, OrganizationSearchField },
  data: () => ({
    cardsTableUK: 0,
    sending: false,
    loading: false,
    selectedCards: [],
    selectedModule: null,
    accepter: null,
    requireAgree: false,
    agreederOrg: null,
    agreeder: null,
  }),
  computed: {
    orgId() {
      return this.$store.state.organization.profile.id
    },
    userId() {
      return this.$store.state.user.profile.userId
    },
    modules() {
      return this.$store.state.modules.filter(m => this.$store.state.organization.modulesIds.includes(m.id) && m.type == 'cards')
    }
  },
  methods: {
    disableNext() {
      if (this.sending || this.selectedCards.length === 0 || this.accepter === null) {
        return true
      }
      if (this.requireAgree && ( this.agreeder === null || this.agreederOrg === null)) {
        return true
      }
      return false
    },
    select: function() {
      this.loading = true
      let cards = []
      this.selectedCards.forEach(c => {
        let card = {
          moduleId: this.selectedModule.id,
          moduleCardId: c.id,
          name: c.name,
          weldMethod: c.weldMethod,
          elementsType: c.elementsType,
          d1: c.d1,
          d2: c.d2,
          s1: c.s1,
          s2: c.s2,
          seamType: c.seamType,
          jointType: c.jointType,
          elem1Material: c.elem1Material,
          elem2Material: c.elem2Material,
          weldMaterial: c.weldMaterial,
          weldPosition: c.weldPosition,
          createdBy: c.createdBy,
          accepterId: this.accepter.userId,
        }
        if (this.requireAgree) {
          card.agreederOrgId = this.agreederOrg.id
          card.agreederId = this.agreeder.userId
        }
        cards.push(card)
      })
      this.$axios.post('/manufacture/card/create', cards).then(r => {
        this.$router.push('index-accept')
      })
    },
    updateCards() {
      this.cardsTableUK = Date.now()
    },
    cardTableChanged() {
      this.selectedCards = []
    },
  },
}
</script>
